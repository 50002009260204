<template>
  <div class="order_container" v-loading="loading">
    <!-- 面包屑导航区域 -->
    <div style="margin-bottom: 20px;margin-left:20px;margin-top: 20px;">
      <el-row>
        <el-col :span="24" align="left">
          <el-button size="mini" type="primary" @click="goback">返回</el-button>
        </el-col>



      </el-row>
    </div>

    <!-- 卡片试图区域 -->
    <div class="box">
      <!-- 左侧内容 -->
      <div style="width:400px;margin-right:0px;padding:10px 30px">
        <div class="box_left">
          <h3 style="margin:0;">合同详情</h3>
          <ul style="padding:0;margin:0;">
            <li>
              <!-- 标题 -->
              <h3 style="margin:0;">{{ this.orderInfo.title }}</h3>
            </li>

            <!-- 类别 -->
            <li>
              <span style="font-size: 14px;color: #262626; line-height: 30px; ">合同类别：</span>
              <span style="font-size: 14px;color: #a2a2a2;line-height: 30px; ">{{
                  this.orderInfo.contractType
              }}</span>
            </li>
            <!-- 编号 -->
            <li>
              <span style="font-size: 14px;color: #262626; line-height: 30px;">合同编号：</span>
              <span style="font-size: 14px;color: #a2a2a2;line-height: 30px;">{{ this.orderInfo.contractNo
              }}</span>
            </li>
            <!-- 签订时间 -->
            <li>
              <span style="font-size: 14px;color: #262626; line-height: 30px;">签订时间：</span>
              <span style="font-size: 14px;color: #a2a2a2;">{{ this.orderInfo.signDate }}</span>
            </li>

            <!-- 签订人 -->
            <li>
              <span style="font-size: 14px;color: #262626; line-height: 30px;">签订人：</span>
              <span style="font-size: 14px;color: #a2a2a2;line-height: 30px;">{{
                  this.orderInfo.signPerson
              }}</span>
            </li>
            <!-- 签订人类型 -->
            <li>
              <span style="font-size: 14px;color: #262626; line-height: 30px;">签订人类型：</span>
              <span style="font-size: 14px;color: #a2a2a2; line-height: 30px;">{{
                  this.orderInfo.signPerson
              }}</span>
            </li>
            <!-- 联系电话 -->
            <li>
              <span style="font-size: 14px;color: #262626; line-height: 30px;">联系电话：</span>
              <span style="font-size: 14px;color: #a2a2a2;line-height: 30px;">{{
                  this.orderInfo.tel
              }}</span>
            </li>
            <!-- 签订地址 -->
            <li>
              <span style="font-size: 14px;color: #262626; line-height: 30px;">签订地址：</span>
              <span style="font-size: 14px;color: #a2a2a2;line-height: 30px;">{{ this.orderInfo.address
              }}</span>
            </li>
            <!-- 所属乡镇 -->
            <li>
              <span style="font-size: 14px;color: #262626; line-height: 30px;">所属乡镇：</span>
              <span style="font-size: 14px;color: #a2a2a2;line-height: 30px;">{{ this.orderInfo.town
              }}</span>
            </li>
            <!-- 合同订单数量 -->
            <li>
              <span style="font-size: 14px;color: #262626; line-height: 30px;">合同订单数量:</span>
              <span style="font-size: 14px;color: #a2a2a2;line-height: 30px; padding-left: 5px;">{{
                  this.orderInfo.orderNum
              }}</span>
            </li>
            <!-- 合同订单价格 -->
            <li>
              <span style="font-size: 14px;color: #262626; line-height: 30px;">合同订单价格:</span>
              <span style="font-size: 14px;color: #a2a2a2;line-height: 30px;  padding-left: 5px;">{{
                  this.orderInfo.contractPrice
              }}</span>
            </li>

            <!-- 现场照片 v-if="index<3".slice(0, 2)  -->
            <li>
              <p style="font-size: 14px;color: #262626; line-height: 30px;">合同：</p>
              <div style="width:50px;height:50px;float:left;margin:2px;margin-top: 20px;"
                v-for="(item, index) in  this.orderInfo.fids" :key="index">
                <el-image style="width:100%;height:100%" :src="img + item" :preview-src-list="[img + item]"></el-image>
              </div>
            </li>

            <div style="width: 216px;
             margin-top: 100px;
height: 40px;
background: #1890FF; font-size: 14px;font-weight: bold;
color: #FFFFFF;
line-height: 40px;text-align: center;" @click="log">查看合同操作日志</div>
          </ul>
        </div>
      </div>
      <el-dialog title="合同操作记录" :visible.sync="openlog" width="40%" top="50px">
        <div class="footer_right">



          <div style="margin-top:20px" v-for="(item, index) in orderLogList" :key="index">
            <el-row type="flex" class="row-bg">
              <el-col :span="24">
                <div>{{ item.msg }}</div>
              </el-col>
            </el-row>

            <el-row type="flex" class="row-bg">
              <el-col :span="24">
                <div style="margin-top:10px">{{ item.createDate }}</div>
              </el-col>
            </el-row>
          </div>


        </div>
        <span slot="footer" class="dialog-footer">

          <el-button type="primary" size="mini" @click="openlog = false">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 右侧内容 -->
      <div style="width:100%;padding:10px 20px">
        <div class="dd">合同审核状态</div>




        <!-- 审核状态    第一个通过 第二个驳回-->
        <div>

          <div class="sh" style="display:flex; align-items: center;justify-content: space-between ">
            <div class="resulet" v-for="(item, index) in  this.list" :key="index">
              <div><span>处理结果:</span> <span style="color: #60B572;">审批通过</span></div>

              <div v-if="item.stage == 1 && item.examDate" style="margin-top:20px"> <span>处理部门:</span>
                <span>农畜牧业局</span>
              </div>
              <div v-if="item.stage == 2" style="margin-top:20px"> <span>处理部门:</span>
                <span>市场监督管理局</span>
              </div>
              <div style="margin-top:20px"> <span>处理时间:</span> <span>{{ item.examDate }}</span></div>

            </div>



          </div>

        </div>

        <!-- 生长 -->
        <div class="order">
          <div style="
            width: 120px;
            margin-bottom:29px;
            height: 25px;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 550;
            color: #000000;
            line-height: 25px;
          ">
            生长阶段记录
          </div>
          <div class="ordercenter"></div>
          <div>
            <el-button type="primary" v-if="role" @click="gotoadd">添加生长记录</el-button>
          </div>
        </div>
        <div v-for="(item, index) in  this.szlist" :key="index">
          <div style="margin-bottom: 27px;
color: #1890FF;font-weight: 600;">{{ item.title }}</div>

          <div style=" width:740px;display: flex;justify-content: space-between;align-items: center;">
            <div>
              <span>监督员姓名：</span>
              <span>{{ item.username }}</span>
            </div>
            <div>
              <span>监督员电话：</span>
              <span>{{ item.tel }}</span>
            </div>
            <div>
              <span>记录时间：</span>
              <span>{{ item.recordDate }}</span>
            </div>
          </div>
          <div style="
            margin-top:29px;
            margin-bottom: 16px;
            height: 25px;
            font-weight: 550;
            color: #000000;
            line-height: 25px;
            
          ">
            记录文件
          </div>

          <div
            style="width:100%;height:100px;display: flex ;justify-content: start; align-items: center;margin:2px;margin-top: 5px; margin-bottom: 30px;  ">
            <el-image v-for="(res, index) in  item.fileDtoList" :key="index"
              style="width:100px;height:100px;margin-right: 10px;"
              :src="res.fileType == 1 ? (img + res.fid) : (img + res.fid + '?vframe/jpg/offset/0')"
              @click="sp(res.fid)"
            >
            </el-image>
          </div>

         





        </div>

        <!-- 销售 -->
        <div class="order" style="margin-top:150px">
          <div style="width: 120px;
            margin-bottom:29px;
            height: 25px;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 550;
            color: #000000;
            line-height: 25px;
          ">
            销售阶段记录
          </div>
          <div class="ordercenter"></div>
          <div>
            <el-button v-if="role" type="primary" @click="goadd">添加销售记录</el-button>
          </div>
        </div>

        <div v-for="(item, index) in  this.xslist" :key="index">
          <div style="margin-bottom: 27px;color: #1890FF;font-weight: 600;">{{ item.title }}</div>
          <div style=" width:740px;display: flex;justify-content: space-between;align-items: center; flex-wrap: wrap;">
            <div>
              <span>销售负责人姓名：</span>
              <span>{{ item.username }}</span>
            </div>
            <div>
              <span>销售负责人电话：</span>
              <span>{{ item.tel }}</span>
            </div>
            <div>
              <span>销售时间：</span>
              <span>{{ item.recordDate }}</span>
            </div>

          </div>
          <div
            style=" width:740px;display: flex;justify-content: space-between;align-items: center; flex-wrap: wrap; margin-top: 24px;">
            <div>
              <span>销售价格：</span>
              <span style="color:#D0021B">{{ item.salePrice }}</span>
            </div>
            <div>
              <span>销售地区：</span>
              <span>{{ item.area }}</span>
            </div>
            <div style=" visibility:hidden">
              <span style=" visibility:hidden"></span>
              <span style=" visibility:hidden"></span>
            </div>

          </div>
          <!-- 检测报告 -->
          <div style="
            margin-top:29px;
            margin-bottom: 16px;
            height: 25px;
            font-weight: 550;
            color: #000000;
            line-height: 25px;
          ">
            检测报告
          </div>
          <div
            style="width:100%;height:100px;display: flex ;justify-content: start; align-items: center;margin:2px;margin-top: 5px; margin-bottom: 30px;  ">
            <el-image v-for="(res, index) in  item.checkFiles" :key="index"
              style="width:100px;height:100px;margin-right: 10px;" :src="img + res" :preview-src-list="[img + res]">
            </el-image>
          </div>

          <!-- 销售图片 -->
          <div style="
             margin-top:30px;
            margin-bottom: 16px;
            height: 25px;
            font-weight: 550;
            color: #000000;
            line-height: 25px;
          " >
            销售文件
          </div>
          <div 
            style="width:100%;height:100px;display: flex ;justify-content: start; align-items: center;margin:2px;margin-top: 5px; margin-bottom: 30px;  ">
            <el-image v-for="(res, index) in  item.saleFileDtoList" :key="index"
              style="width:100px;height:100px; margin-right: 10px;" 
            :src="res.fileType == 1 ? (img + res.fid) : (img + res.fid + '?vframe/jpg/offset/0')"
              @click="sp(res.fid)">
            </el-image>
          </div>


       
       
        </div>
      </div>
    </div>


    <el-dialog title="添加生长记录" :visible="showDialog" @close="btnCancel">
      <!-- 表单 -->
      <el-form ref="perForm" :model="bmsGrowRecordDto" :rules="rules" label-width="120px">

        <el-form-item label="监察员名称" prop="username">
          <el-input v-model="bmsGrowRecordDto.username" style="width: 50%" />
        </el-form-item>
        <el-form-item label="联系电话" prop="tel">
          <el-input v-model="bmsGrowRecordDto.tel" style="width: 50%" />
        </el-form-item>
        <el-form-item label="记录时间" prop="recordDate">
          <el-date-picker v-model="bmsGrowRecordDto.recordDate" type="date" @change="selectChange1"
            value-format="yyyy-MM-dd" placeholder="选择日期">

          </el-date-picker>
        </el-form-item>
        <el-form-item label="合同文件" prop="fileDtoList">
          <el-upload class="upload-demo" ref="up" action="http://up.qiniup.com" :show-file-list="true"
            :on-preview="handlePreview" :file-list="fileList" accept="" :on-progress="uploadVideoProcess"
            :on-remove="handleRemovesp" list-type="picture" :on-success="handleAvatarSuccesssp" :data="postData"
            :before-upload="beforeAvatarUploadsp"
            :disabled="loadProgress == 0 ? false : loadProgress == 101 ? false : true">
            <el-button type="primary" :disabled="loadProgress == 0 ? false : loadProgress == 101 ? false : true">点击上传
            </el-button>

          </el-upload>

        </el-form-item>



      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog title="添加销售记录" top='50px' :visible="showDialog1" @close="btnCancel1">
      <!-- 表单 -->
      <el-form ref="perForm1" :model="bmsSaleRecordDto" :rules="rules1" label-width="120px">

        <el-form-item label="销售负责人名称" prop="username">
          <el-input v-model="bmsSaleRecordDto.username" style="width: 50%" />
        </el-form-item>
        <el-form-item label="地区" prop="area">
          <el-input v-model="bmsSaleRecordDto.area" style="width: 50%" />
        </el-form-item>
        <el-form-item label="联系电话" prop="tel">
          <el-input v-model="bmsSaleRecordDto.tel" style="width: 50%" />
        </el-form-item>
        <el-form-item label="销售价格" prop="salePrice">
          <el-input v-model="bmsSaleRecordDto.salePrice" style="width: 50%" />
        </el-form-item>

        <el-form-item label="记录时间" prop="recordDate">
          <el-date-picker v-model="bmsSaleRecordDto.recordDate" type="date" @change="selectChange2"
            value-format="yyyy-MM-dd" placeholder="选择日期">

          </el-date-picker>
        </el-form-item>
        <el-form-item label="检测报告图片" prop="checkFiles">
          <el-upload class="upload-demo" :data="postData1" list-type="picture-card" :file-list="fileList1"
            show-file-list :on-success="handleavatarsuccess1" :before-upload="beforeUpload1"
            action="http://up.qiniup.com" :on-remove="handleRemove1" multiple>
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="销售文件信息" prop="saleFileDtoList">
          <el-upload class="upload-demo" ref="up" action="http://up.qiniup.com" :show-file-list="true"
            :on-preview="handlePreview" list-type="picture" :file-list="fileList2" accept=""
            :on-progress="uploadVideoProcess" :on-remove="handleRemovesp1" :on-success="handleAvatarSuccesssp1"
            :data="postData2" :before-upload="beforeAvatarUploadsp1"
            :disabled="loadProgress == 0 ? false : loadProgress == 101 ? false : true">
            <el-button type="primary" :disabled="loadProgress == 0 ? false : loadProgress == 101 ? false : true">点击上传
            </el-button>

          </el-upload>

        </el-form-item>






      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK1">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel1">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import {
  getUploadToken,
  getcontractInfoDetail,
  contractLog,
  reviewResult,
  growRecordList,
  saleRecordList,
  addgrowRecord,
  addsaleRecord


} from "../../request/http";
export default {
  data() {
    return {
      loadProgress: 0,
      openlog: false,
      loading: false,//加载动画
      contractId: "", //合同id
      orderInfo: {}, //合同详情信息
      orderLogList: [],
      list: [],
      fileList: [],
      postData: {
        token: "", //上传凭证
        fid: "", //文件id
        fileDomain: "" //文件域名
      },
      fileType: null,
      fileType1: null,
      bmsGrowRecordDto: {
        contractId: null,

        fileDtoList: [],
        recordDate: "",
        username: "",
        tel: ""
      },
      showDialog: false, //生长
      showDialog1: false,
      role: null,
      szlist: [],
      xslist: [],
      getRecordListDto: {
        pageNum: 1,
        pageSize: 100,
        companyId: null,
        contractId: null
      },
      fileList1: [],
      fileList2: [],
      postData1: {
        token: "", //上传凭证
        fid: "", //文件id
        fileDomain: "" //文件域名
      },
      postData2: {
        token: "", //上传凭证
        fid: "", //文件id
        fileDomain: "" //文件域名
      },
      dialogVisible: false, //显示添加或编辑框
      bmsSaleRecordDto: {
        contractId: null,
        saleFileDtoList: [],
        checkFiles: [],
        recordDate: "",
        username: "",
        tel: "",
        salePrice: null,
        area: '',
      },
      rules: {

        files: [
          { required: true, message: "生长记录图片不能为空", trigger: "blur" },
        ],
        fileDtoList: [{
          required: true, message: "上传文件不能为空", trigger: "blur"
        },
        ],
        recordDate: [
          { required: true, message: "生长记录时间不能为空", trigger: "blur" },
        ],
        username: [
          { required: true, message: "监察员名称不能为空", trigger: "blur" },
        ],
        tel: [
          { required: true, message: "联系电话不能为空", trigger: "blur" },
          {
            pattern:
              /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: "请输入正确的手机号码",
          },
        ],


      }, rules1: {

        saleFileDtoList: [
          { required: true, message: "上传不能为空", trigger: "blur" },
        ],
        recordDate: [
          { required: true, message: "销售时间不能为空", trigger: "blur" },
        ],

        area: [
          { required: true, message: "销售地区不能为空", trigger: "blur" },
        ],
        checkFiles: [
          { required: true, message: "检测报告图片不能为空", trigger: "blur" },
        ],
        username: [
          { required: true, message: "销售人负责人姓名不能为空", trigger: "blur" },
        ],
        salePrice: [
          { required: true, message: "销售价格不能为空", trigger: "blur" },
        ],
        tel: [
          { required: true, message: "销售人负责人电话不能为空", trigger: "blur" },
          {
            pattern:
              /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: "请输入正确的手机号码",
          },
        ],


      },

    };
  },
  created() {
    this.role = localStorage.getItem('role')
    if (this.role == 3 || this.role == 4) {
      this.role = false
    }

    this.getRecordListDto.companyId = this.$route.query.companyId;
    this.getRecordListDto.contractId = this.$route.query.contractId;
    this.bmsGrowRecordDto.contractId = this.$route.query.contractId;
    this.bmsSaleRecordDto.contractId = this.$route.query.contractId;
    this.contractId = this.$route.query.contractId; //订单id
    console.log(this.contractId);
    this.growRecordList();
    this.saleRecordList();
    this.getcontractInfoDetail(); // 获取订单详情
    this.contractLog()
    this.reviewResult()



  },
  computed: {},

  methods: {
    handlePreview(file) {
      console.log(file);
      let key;

      file.key ? key = file.key.fid : key = file.response.key



      window.open('http://farmfile.uutele.com/' + key, '_blank')

    },

    sp(val){
      window.open('http://farmfile.uutele.com/' + val, '_blank')
    },
    async log() {

      this.contractLog()
      // const { data } = await contractLog(this.contractId);

      // let list = data.data.records
      // this.orderLogList = list;
      // console.log(this.orderLogList);


      this.openlog = true

    },
    selectChange1(value) {
      this.bmsGrowRecordDto.recordDate = value
      console.log(this.bmsGrowRecordDto.recordDate);
    },
    selectChange2(value) {
      this.bmsSaleRecordDto.recordDate = value
      console.log(this.bmsSaleRecordDto.recordDate);
    },
    //上传图片
    async beforeUpload2(file) {
      let { data } = await getUploadToken();
      console.log(data);
      this.postData2.token = data.data.token;
      this.postData2.fid = data.data.fid;
      this.postData2.fileDomain = data.data.fileDomain;
    },
    //上传图片成功回调函数
    handleavatarsuccess2(response, file, fileList) {
      console.log(response, file, fileList);

      this.bmsSaleRecordDto.saleFileDtoList.push({
        fid: response.key,
        fileType: 1
      });
      console.log(this.bmsSaleRecordDto.saleFileDtoList);
      this.$message.success("上传图片成功");
    },
    //删除多张图片的一张
    handleRemove2(file) {
      let key;

      file.key ? key = file.key : key = file.response.key


      let i = this.bmsSaleRecordDto.saleFileDtoList.findIndex((item) => item.fid === key)

      console.log(key, i);
      // console.log(i);
      //  this.bmsSaleRecordDto.saleFileDtoList=  this.bmsSaleRecordDto.saleFileDtoList.filter(item => item.fid!==key)
      this.bmsSaleRecordDto.saleFileDtoList.splice(i, 1);
      console.log(this.bmsSaleRecordDto.saleFileDtoList);

    },


    //上传图片
    async beforeUpload1(file) {
      let { data } = await getUploadToken();
      console.log(data);
      this.postData1.token = data.data.token;
      this.postData1.fid = data.data.fid;
      this.postData1.fileDomain = data.data.fileDomain;
    },
    //上传图片成功回调函数
    handleavatarsuccess1(response, file, fileList) {
      console.log(response, file, fileList);
      console.log(this.bmsSaleRecordDto.checkFiles);
      this.bmsSaleRecordDto.checkFiles.push(response.key);
      this.$message.success("上传图片成功");
    },
    //删除多张图片的一张
    handleRemove1(file) {
      let key;

      file.key ? key = file.key : key = file.response.key


      let i = this.bmsSaleRecordDto.checkFiles.indexOf(key);

      console.log(key, i);
      // console.log(i);
      //  this.bmsSaleRecordDto.saleFileDtoList=  this.bmsSaleRecordDto.saleFileDtoList.filter(item => item.fid!==key)
      this.bmsSaleRecordDto.checkFiles.splice(i, 1);
      console.log(this.bmsSaleRecordDto.checkFiles);

    },
    //上传图片
    async beforeUpload(file) {
      let { data } = await getUploadToken();
      console.log(data);
      this.postData.token = data.data.token;
      this.postData.fid = data.data.fid;
      this.postData.fileDomain = data.data.fileDomain;
    },
    //上传图片成功回调函数
    handleavatarsuccess(response, file, fileList) {
      console.log(response, file, fileList);


      this.bmsGrowRecordDto.fileDtoList.push({
        fid: response.key,
        fileType: 1
      });
      this.$message.success("上传图片成功");
      console.log(this.bmsGrowRecordDto.fileDtoList);
    },
    //删除多张图片的一张
    handleRemove(file) {
      console.log(file);
      let key;

      file.key ? key = file.key : key = file.response.key

      let i = this.bmsGrowRecordDto.fileDtoList.findIndex((item) => item.fid === key)

      // let i = this.bmsGrowRecordDto.fileDtoList.indexOf(key);

      console.log(key, i);
      // console.log(i);
      //  this.bmsGrowRecordDto.files=  this.bmsGrowRecordDto.files.filter(item => item.fid!==key)
      this.bmsGrowRecordDto.fileDtoList.splice(i, 1);
      console.log(this.bmsGrowRecordDto.fileDtoList);

    },

    handleRemovesp(file) {
      console.log(file);
      let key;

      file.key ? key = file.key.fid : key = file.response.key

      let i = this.bmsGrowRecordDto.fileDtoList.findIndex((item) => item.fid === key)

      // let i = this.bmsGrowRecordDto.fileDtoList.indexOf(key);

      console.log(key, i);
      // console.log(i);
      //  this.bmsGrowRecordDto.files=  this.bmsGrowRecordDto.files.filter(item => item.fid!==key)
      this.bmsGrowRecordDto.fileDtoList.splice(i, 1);
      console.log(this.bmsGrowRecordDto.fileDtoList);

    },
    // 上传方法
    handleAvatarSuccesssp(response, file, fileList) {
      console.log('上传成功', response, file.raw.type)
      if (file.raw.type.includes('image')) {
        this.bmsGrowRecordDto.fileDtoList.push({
          fid: response.key,
          fileType: 1
        });
      }
      else if (file.raw.type.includes('video')) {
        this.bmsGrowRecordDto.fileDtoList.push({
          fid: response.key,
          fileType: 2
        });
        file.url = "http://farmfile.uutele.com/" + response.key + '?vframe/jpg/offset/0'
      }

      this.loadProgress = this.loadProgress + 1

      console.log(this.bmsGrowRecordDto.fileDtoList);
    },
    async beforeAvatarUploadsp(file) {
      this.loadProgress = 0
      let { data } = await getUploadToken()
      if (data == '' || data == null) {
        this.message.error('上传文件时获取Token失败！')
        return
      }
      this.postData.token = data.data.token;
      this.postData.fid = data.data.fid;
      this.postData.fileDomain = data.data.fileDomain;
    },
    uploadVideoProcess(event, file, fileList) {
      this.loadProgress = parseInt(event.percent); // 动态获取文件上传进度
      console.log(this.loadProgress);
    },

    handleRemovesp1(file) {
      console.log(file);
      let key;

      file.key ? key = file.key.fid : key = file.response.key

      let i = this.bmsSaleRecordDto.saleFileDtoList.findIndex((item) => item.fid === key)

      // let i = this.bmsSaleRecordDto.saleFileDtoList.indexOf(key);

      console.log(key, i);
      // console.log(i);
      //  this.bmsSaleRecordDto.files=  this.bmsSaleRecordDto.files.filter(item => item.fid!==key)
      this.bmsSaleRecordDto.saleFileDtoList.splice(i, 1);
      console.log(this.bmsSaleRecordDto.saleFileDtoList);

    },
    // 上传方法
    handleAvatarSuccesssp1(response, file, fileList) {
      console.log('上传成功', response, file.raw.type)
      if (file.raw.type.includes('image')) {
        this.bmsSaleRecordDto.saleFileDtoList.push({
          fid: response.key,
          fileType: 1
        });
      }
      else if (file.raw.type.includes('video')) {
        this.bmsSaleRecordDto.saleFileDtoList.push({
          fid: response.key,
          fileType: 2
        });
        file.url = "http://farmfile.uutele.com/" + response.key + '?vframe/jpg/offset/0'
      }

      this.loadProgress = this.loadProgress + 1

      console.log(this.bmsSaleRecordDto.saleFileDtoList);
    },
    async beforeAvatarUploadsp1(file) {
      this.loadProgress = 0
      let { data } = await getUploadToken()
      if (data == '' || data == null) {
        this.message.error('上传文件时获取Token失败！')
        return
      }
      this.postData2.token = data.data.token;
      this.postData2.fid = data.data.fid;
      this.postData2.fileDomain = data.data.fileDomain;
    },




























    async growRecordList() {
      let { data } = await growRecordList(this.getRecordListDto);
      let list = data.data;

      if (data.code == 1) {
        this.szlist = []
      }
      else {
        this.szlist = list.records;
        console.log(this.szlist);
      }



    },
    async saleRecordList() {
      let { data } = await saleRecordList(this.getRecordListDto);
      let list = data.data;

      if (data.code == 1) {
        this.xslist = []
      }
      else {
        this.xslist = list.records;
        console.log(this.xslist);
      }


      this.loading = false
    },
    //获取审核结果
    async reviewResult() {

      const { data } = await reviewResult(this.contractId);

      this.list = data.data
      console.log(this.list);

      if (data.code !== 0) {
        return this.$message.error(data.msg);
      }




    },
    async gotoadd() {
      this.showDialog = true

    },
    async goadd() {
      this.showDialog1 = true

    },
    async btnOK() {
      let { data } = await addgrowRecord(this.bmsGrowRecordDto);
      console.log(data);
      if (data.code == 0) {
        this.$message.success("新增成功");
      } else {
        console.log(data.msg);
        this.$message({
          type: "error",
          message: data.msg,
        });
      }
      this.growRecordList();

      this.showDialog = false;
    },
    btnCancel() {

      this.bmsGrowRecordDto = {

        contractId: this.$route.query.contractId,
        fileDtoList: [],
        recordDate: "",
        username: "",
        tel: ""
      };
      this.loadProgress = 0,
        this.fileType = null
      this.fileList = []
      this.$refs.perForm.resetFields();
      this.showDialog = false;
    },
    async btnOK1() {
      let { data } = await addsaleRecord(this.bmsSaleRecordDto);
      console.log(data);
      if (data.code == 0) {
        this.$message.success("新增成功");
      } else {
        console.log(data.msg);
        this.$message({
          type: "error",
          message: data.msg,
        });
      }
      this.saleRecordList();

      this.showDialog1 = false;

    },
    btnCancel1() {
      this.loadProgress = 0,
        this.fileType1 = null
      this.bmsSaleRecordDto = {
        contractId: this.$route.query.contractId,
        saleFileDtoList: [],
        checkFiles: [],
        recordDate: "",
        username: "",
        tel: "",
        salePrice: null,
        area: '',
      };

      this.fileList1 = []
      this.fileList2 = []
      this.$refs.perForm1.resetFields();
      this.showDialog1 = false;
    },
    goback() {
      this.$router.go(-1)
    },
    async contractLog() {
      const { data } = await contractLog(this.contractId);

      let list = data.data.records
      this.orderLogList = list;
      console.log(this.orderLogList);

    },
    // 获取订单详情
    async getcontractInfoDetail() {
      const { data } = await getcontractInfoDetail(this.contractId);

      let list = data.data
      if (data.code !== 0) {
        return this.$message.error(data.msg);
      }
      this.orderInfo = list;
      console.log(this.orderInfo);
      this.loading = false



    },












  }
};
</script>

<style lang="scss" scoped>
.order {
  display: flex;
  margin-top: 80px;
}

.ordercenter {
  flex: 0.75;
}

.order_container {
  .resulet {
    display: flex;

    flex-direction: column;
    justify-content: center;

  }

  .sh {
    &::after {
      content: "";
      width: 272px;
    }
  }

  .box {
    display: flex;

    .box_left {
      ul li {
        list-style: none;
        margin-top: 20px;


      }
    }

    .box_right {
      .el-button {
        width: 112px;
        height: 40px;
        color: #d0021bff;
        background: rgba(208, 2, 27, 0.05);
        border-radius: 4px;
        border: 1px solid #d0021b;
        margin-left: 20px;
      }

      .select_box {
        margin-left: 35px;
      }

      .el-select {
        width: 200px;
        margin-right: 10px;
      }

      .btn {
        width: 80px;
        height: 32px;
        color: #ffffff;
        background: #007afc;
        border-radius: 4px;
        border: 1px solid #007afc;
      }

      .ipt {
        width: 200px;
        height: 40px;
        margin-right: 10px;
      }

      .btn_one {
        width: 60px;
        height: 30px;
        color: #ffffff;
        background: #007afc;
        border-radius: 4px;
        border: 1px solid #007afc;
      }

      .bigBox {
        display: flex;
        justify-content: space-between;

        &::after {
          content: "";
          width: 1px;
        }

        .contain {
          width: 100%;
          padding: 0 10px 0 10px;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
          border-radius: 8px;
          border: 1px solid #e9e9e9;

          // margin: auto;
          .one {
            display: flex;
            justify-content: space-between;
            height: 50px;
            line-height: 50px;
            border-bottom: 1px solid #dededeff;

            .left {
              font-size: 14px;
              font-family: PingFang-SC-Medium, PingFang-SC;
              color: rgba(0, 0, 0, 0.85);
            }

            .right {
              width: 250px;
              font-size: 14px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              color: rgba(0, 0, 0, 0.85);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: right;
              cursor: pointer;
            }
          }
        }
      }
    }

    .div__body {
      padding: 0;
      margin: 0;
    }

    .footer {
      display: flex;
      margin-top: 80px;

      .footer_left {
        width: 50%;

        h5 {
          margin: 0;
        }
      }

      .footer_right {
        width: 70%;

        h5 {
          margin: 0;
        }
      }
    }
  }

  li {
    list-style: none;
  }

  .btn {
    width: 80px;
    height: 32px;
    color: #ffffff;
    background: #007afc;
    border-radius: 4px;
    border: 1px solid #007afc;
  }

  .ipt {
    width: 200px;
    height: 40px;
    margin-right: 10px;
  }

  .el-select {
    width: 200px;
    margin-right: 10px;
  }
}

.dd {
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 600;
}

.big {}

.stitle {
  margin-bottom: 20px;
  font-size: 14px 600
}

.ph {
  margin-bottom: 20px;
}
</style>



 
          